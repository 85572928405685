@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
display:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.column-AgeGroupRank,
.column-CountryAgeGroupRank {
  max-width: 25px;
}

.RaCreate-card form {
  max-width: 800px;
  margin: 0 auto;
}

.RaFileInput-dropZone {
  border-style: dashed;
  border-color: #cccccc;
  border-width: 2px;
}

.MuiAvatar-circular>svg {
  display: none;
}

.ra-field-ContactId {
  padding: 2px;
}
#main-content {
  background-color: white !important;
  padding: 0;
}
.RaLayout-appFrame{
  margin-top:0 !important;
}
.RaAppBar-menuButton{
  display:none !important;
}
.logo{
  max-width:350px;
  display:flex;
  position:relative;
  text-align:center;
  margin: 0 auto;
}

.flag{
  vertical-align: middle;
  padding-left: 10px
}

.topsearchicon {
  position:absolute;
  /* display:none; */
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  padding-left: 8px !important;
  padding-right: 8px !important;
}



/* #resultList {
  width: 100vw !important;
} */

#react-admin-title {
  padding-left: 12px;
}
.resultContainer {
  padding: 0 !important;
  max-width: 100vw;
  width: 100%;
  margin: 0 auto;
}

.secode input{
  width: 0;
  border: none;
}
.rankSum>.wrapper>.MuiAlert-root {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  align-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.MuiToolbar-root>form {
  margin-top: 0;
}


/* Result toggle styles */
.summaryRow{
    background-color: #fafafa;
    border-top: solid 1px #e3e2e3;
    display: flex;
    align-items: center;
    padding: 15px 25px;
  }
  .summaryRow:last-child{
    border-bottom: solid 1px #e3e2e3;
  }
  .summaryRow .summaryHeading, .summaryRow .summaryTime{
    font-size: 1.5em;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
  .summaryRow .summaryTime{
    margin-left: auto;
  }
  .summaryRow.overallRow{
    border-bottom: none;
    background: #fff;
  }
  .summaryRow.overallRow  .summaryTime{
    margin-right: 30px;
  }
  .toggleContent {
    height: 0;
    overflow: hidden;
    transition: height 0.15s linear;
  }
  .toggleContent.is-visible {
    height: 150px;
    transition: height 0.15s linear;
  }

  #resultList .MuiTableCell-sizeSmall {
    padding-top: 0;
  }
  .detailsButton{
    content: '';
    width: 8px;
    height: 8px;
    margin: 7px 0 0 20px;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
    border-right: solid 3px #999;
    border-bottom: solid 3px #999;
    transition: all .15s ease;
  }
  .toggled .detailsButton{
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    transition: all .15s ease;
  }
  .resultContainer{
    padding: 20px;
  }
  .resultContainer h3{
    font-size: 18px;
    color: #dc0025;
    text-transform: uppercase;
  }
  .resultContainer h4{
    font-size: 17px;
    color: #000;
    text-transform: uppercase;
  }
  .resultContainer .genInfo .text{
    display: inline;
    margin-right: 10px;
    text-align: center;
  }
  .resultContainer .genInfo .countryFlag img{
    max-width: 42px;
  }
  .resultContainer .rankSum{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: no-wrap;
    -webkit-flex-wrap: no-wrap;
    width: 100%;
    border-top: solid 1px #e3e2e3;
    border-bottom: solid 1px #e3e2e3;
    justify-content: space-evenly;
  }
  .resultContainer .rankSum .wrapper{
    display: flex;
    width: 100%;
  }
  .resultContainer .rankSum .wrapper .text{
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    padding: 2px 14px;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    align-items: center;
    border-right: solid 1px #e3e2e3;
    -webkit-transform: skew(-5deg);
    transform: skew(-5deg);
  }
  .resultContainer .rankSum .wrapper .text:last-child{
    border-right: none;
  }
  .resultContainer .rankSum .wrapper .text p{
    -webkit-transform: skew(5deg);
    transform: skew(5deg);
  }

  .resultContainer .rankSum .wrapper .text .rankNum{
    margin: 15px 0 10px 0;
    font-size: 2.2em;
    line-height: 1em;
  }
  .resultContainer .rankSum .wrapper .text .rankText{
    font-weight: bold;
    margin: 0 0 15px 0;
    text-transform: uppercase;
  }

  /* Run Rock 'n' Roll Style overrides */
  .resultContainer.runResultContainer .rankSum{
    border-top: solid 1px #e3e2e3;
  }

  .resultContainer.runResultContainer .rankSum .wrapper .text{
    -webkit-transform: skew(0deg);
    transform: skew(0deg);
  }

  .tableRow.tableHeader div.wrapper, .tableRow.tableFooter div.wrapper{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: no-wrap;
    -webkit-flex-wrap: no-wrap;
    width: 100%;
    font-weight: 400;
    text-transform: uppercase;
    vertical-align: middle;
    font-size: 17px;
  }
  .tableRow.tableHeader div.wrapper{
    background-color: #fff;
    color: #000;
    border-top: solid 1px #e3e2e3;
    border-bottom: solid 1px #e3e2e3;
  }
  .tableRow.tableFooter div.wrapper{
    background-color: #fafafa;
    color: #000;
    border-bottom: solid 1px #e3e2e3;
  }
  .tableRow.tableHeader div.wrapper div.text{
    padding: 5px 14px;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    width: 25%;
    text-align: center;
  }
  .tableRow.tableFooter div.wrapper div.text{
    padding: 5px 14px;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    width: 25%;
    text-align: center;
  }
  .resultContainer thead tr th{
    background-color: #f0efef;
    color: #dc0025;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 5px 14px;
  }
  .resultContainer tbody tr td{
    background-color: #fff;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 5px 14px;
  }
  .ra-field-id > div {width:100%;}
  .ra-field-id > div > label {
    display:none;
  }
  /* Summary Header */
  .raceSum.tableWrap div.summaryHeaderCont{
    align-items: center;
    display: flex;
    flex-basis: 50%;
    justify-content: space-between;
  }
  .raceSum.tableWrap div.summaryHeaderCont h3{
    flex-basis: 50%;
    text-align: left;
  }
  /* Certificate button */
  .raceSum.tableWrap div.summaryHeaderCont .certDiv button, .awaCertDiv button{
    border: none;
    background-color: #fff;
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
  }
.raceSum.tableWrap div.summaryHeaderCont .certDiv, .awaCertDiv {
    position: relative;
    padding: 8px 12px;
    width: 135px;
    border: 2px solid #232323;
    color: #232323;
    text-decoration: none;
    text-align: left;
}
.awaCertDiv{
  display: flex;
  margin-top: 42px;
}
.awaImage{
  display: inline-block;
  float: left;
  height: 75px;
  width: 75px;
  margin: -19px 25px 0 25px;
}
.raceSum.tableWrap div.summaryHeaderCont .certButton, .certButton{
  color: #232323;
  text-decoration: none;
  cursor: pointer;
}
.awaDiv.hidden{
  display: none;
}
.awaCertDiv.hidden{
  display: none;
}
.raceSum.tableWrap div.summaryHeaderCont .certButton:hover, .certButton:hover{
  color: #232323;
}

.raceSum.tableWrap div.summaryHeaderCont .certButton:before, .certButton:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.raceSum.tableWrap div.summaryHeaderCont .certButton:hover:before, .certButton:hover:before{
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.raceSum.tableWrap div.summaryHeaderCont .certDiv .pdfIcon, .awaCertDiv .pdfIcon{

  position: absolute;
  right: 5px;
  bottom: 5px;
  height: .9em;
}